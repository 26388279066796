.page--login,
.page--register,
.page--finish,
.page--reset,
.page--forgot,
.page--start {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 90px;
  .btn-success {
    font-size: 12px;
    font-weight: bold;
    @include media-breakpoint-up(xl) {
      font-size: 18px;
    }
  }

  .success-wrap {
    background-color: #f7fdef;
    border: #74be0d 1px solid;
    border-radius: 10px;
    display: flex;
    margin-bottom: 23px;
    padding: 16px 23px 16px 22px;
    justify-content: space-between;
    position: relative;
    right: -100px;
    animation: enter 0.5s ease-in-out;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;

    @include media-breakpoint-up(xl) {
      padding: 20px 41px 19px 38px;
    }
    .thumbs-up {
      margin-right: 16px;
      align-self: center;
    }
    .success-title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      @include media-breakpoint-up(xl) {
        font-size: 22px;
      }
    }
    .success-sub {
      font-size: 14px;
      text-align: center;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
  }
  .logo {
    height: 27px;
    width: 95px;
    background: url(../../media/logo-login.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .card-wrapper-row {
    margin-top: 12px;
    padding: 35px 40px;
    width: 100%;
    max-width: 336px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      padding: 35px 40px;
    }
    a {
      font-weight: 400;
    }
    @include media-breakpoint-up(xl) {
      max-width: 506px;
    }
    .email {
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
    .login-fail {
      color: #c90808;
      font-size: 13px;
      position: relative;
      left: 2px;

      a {
        text-decoration: none;
        color: #c90808;
      }

      &.not-activated {
        cursor: pointer;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
      @include media-breakpoint-up(xl) {
        font-size: 25px;
        margin-bottom: 18px;
      }
    }
    .sub-title {
      font-size: 13px;
      text-align: center;
      margin-bottom: 20px;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }
    .inputs {
      border-radius: 10px;
      width: 100%;
      &.error-input,
      .error-input {
        .form-control,
        input {
          box-shadow: rgba(201, 8, 8, 1) 0px 1px 2px, rgba(201, 8, 8, 1) 0px 0px 0px 2px;
        }
        .err {
          font-size: 11px;
          color: #c90808;
          position: relative;
          &.main {
            left: 0;
            right: 0;
          }
        }
      }
      .input-group {
        display: flex;
        flex-direction: column;
        .input-wrap {
          position: relative;
        }
        .password-wrap {
          position: relative;
          .eye-icon {
            position: absolute;
            right: 12px;
            cursor: pointer;
            top: 4px;
            height: 14px;
            width: 23px;
            cursor: pointer;

            @include media-breakpoint-up(xl) {
              height: 17px;
              top: 12px;
              right: 18px;
            }
          }
        }
      }
    }
    .keep-me-signed {
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      @include media-breakpoint-up(xl) {
        align-items: center;
      }
      .form-check-input {
        margin-right: 5px;
        height: 13px;
        width: 13px;
        @include media-breakpoint-up(xl) {
          height: 17px;
          width: 17px;
          margin-top: 0;
        }
      }
      .keep-me-signed-txt {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(xl) {
          font-size: 16px;
        }
      }
      .forgot-password {
        cursor: pointer;
        margin-left: auto;

        a {
          color: #1e9be6;
          &:hover {
            text-decoration: underline;
            text-decoration-color: #1e9be6;
          }
        }
        @include media-breakpoint-up(xl) {
          font-size: 16px;
          position: relative;
        }
      }
    }
    .login-btn-wrap {
      max-width: 426px;
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
      .login-btn,
      .register-btn {
        width: 100%;
        text-transform: capitalize;
        height: 41px;
        font-size: 15px;

        @include media-breakpoint-up(xl) {
          height: 46px;
          font-size: 18px;
        }
      }
    }
    .create-account {
      color: #1e9be6;
      cursor: pointer;
      font-size: 12px;
      text-decoration: underline;
      text-align: center;
      margin-top: 12px;
      padding: 0px 50px;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
  }
  .support {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #030133;
    text-align: center;
    .support-txt {
      font-size: 13px;
      margin-top: 15px;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
    .contact-support {
      font-size: 14px;
      color: #74be0d;
      font-weight: 600;
      margin-top: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title,
    .email {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      @include media-breakpoint-up(xl) {
        font-size: 25px;
      }
    }
    .email {
      margin-top: 0;
      font-size: 16px;
      @include media-breakpoint-up(xl) {
        font-size: 25px;
      }
    }
    .activate {
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
        max-width: 80%;
      }
    }
    .locate {
      font-size: 14px;
      margin-top: 20px;
      @include media-breakpoint-up(xl) {
        max-width: 65%;
        font-size: 18px;
      }
    }
    .verified {
      margin-top: 50px;
      color: #1e9be6;
      text-decoration: underline;
      font-size: 13px;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
  }
}
.page--register {
  .card-wrapper-row {
    .title {
      font-size: 16px;
      @include media-breakpoint-up(xl) {
        font-size: 25px;
        margin-bottom: 30px;
      }
    }
    .weve-sent,
    .email {
      font-size: 14px;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
    .email {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }
    }
    .activate {
      font-size: 13px;
      font-weight: 400;
      @include media-breakpoint-up(xl) {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 8px;
      }
    }

    .locate {
      color: #95939b;
      font-size: 11px;
      font-weight: 400;
      @include media-breakpoint-up(xl) {
        font-size: 13px;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 46px;
      }
    }
    .sub-title {
      @include media-breakpoint-up(xl) {
        margin-top: 13px;
        font-weight: 400;
      }
    }
    .inputs {
      .input-group {
        .input-wrap {
          margin-bottom: 15px;
        }
      }
    }
  }
  .email-sent {
    width: 54px;
    height: 57px;
    @include media-breakpoint-up(xl) {
      width: 80px;
      height: 80px;
    }
  }
}
.page--forgot {
  position: relative;
  .back-to-login {
    position: absolute;
    top: 24px;
    left: 28px;
    color: #9d9d9d;
    text-decoration: none;
    display: flex;
    gap: 6px;
    font-size: 13px;
  }
  .card-wrapper-row {
    .create-account {
      color: #74be0d;
    }
  }
}
.page--register {
  .card-wrapper-row {
    .sub-title {
      @include media-breakpoint-up(xl) {
        margin-top: 13px;
        font-weight: 400;
      }
    }
    .inputs {
      .input-group {
        .input-wrap {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@keyframes enter {
  0% {
    right: -100px;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

.page--reset {
  .success {
    .title {
      &.success-title {
        font-size: 16px;
        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }
      }
    }
  }
}

.page--finish,
.page--account,
.page--reset {
  .password-checklist {
    margin-bottom: 58px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
    .checklist-title {
      font-weight: 600;
      font-size: 13px;
      color: #565360;
      margin-bottom: 12px;
    }
    .checklist-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-left: 12px;
      color: #6b6874;
      .status {
        width: 10px;
        position: relative;
        .circle {
          width: 10px;
          height: 10px;
          background: rgba(0, 0, 0, 0.14);
          border-radius: 30px;
          content: '';
        }
      }
    }
  }
}

.page--finish {
  .card-wrapper-row {
    .inputs,
    .sub-title {
      margin-bottom: 0;
    }
    .inputs {
      .input-group {
        .input-wrap {
          margin-bottom: 10px;
        }
      }
    }
    .sub-title,
    .email {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .email {
      @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
      }
    }
  }
}

.title-wrapper {
  margin-top: 27px;
  position: relative;
  @include media-breakpoint-up(lg) {
    margin-top: 46px;
  }
  &.sub {
    @include media-breakpoint-up(lg) {
      margin-top: 16px;
    }
    .page-title {
      .text {
        &.subtitle {
          @include media-breakpoint-up(lg) {
            font-size: 24px;
          }
        }
      }
    }
  }
  .page-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .text {
      font-size: 27px;
      font-weight: bold;
      line-height: 39px;
      text-transform: uppercase;
    }
  }

  .page-title-navigator {
    position: relative;
    top: -47px;
    right: auto;
    left: auto;
    display: flex;
    justify-content: flex-end;
    .link {
      color: #69b300;
      cursor: pointer;
      text-decoration: underline;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  // Page title with icon
  .page-title-with-icon {
    .icon {
      margin-right: 25px;
    }

    .text {
      &.subtitle {
        font-size: 24px;
        line-height: 35px;
      }
    }
  }

  // Page title without icon
  .page-title-separator {
    margin-bottom: 27px;

    .text {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        left: 0;
        height: 3px;
        width: 0;
        min-width: 0;
        background-color: #69b300;
        animation: fill-width 0.63s cubic-bezier(0.79, -0.18, 0, 1.38) forwards 0.16s;
      }

      &.subtitle {
        font-size: 19px;
        line-height: 27px;

        &:after {
          animation: fill-width-sm 0.63s cubic-bezier(0.79, -0.18, 0, 1.38) forwards 0.16s;
        }
      }
    }
  }

  @keyframes fill-width {
    to {
      width: 110%;
      min-width: 165px;
    }
  }

  @keyframes fill-width-sm {
    to {
      width: 110%;
      min-width: 145px;
    }
  }
}

.page--plan {
  scroll-behavior: smooth;
  .card-wrapper-row {
    position: relative;

    &.mobile {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      .plan-wrapper {
        .seats-dropdown {
          width: 80%;
          max-width: 100%;
          margin: auto;
          height: 40px;
          text-align: center;
          margin-bottom: 15px;
          .dropdown-toggle {
            background-color: rgba(231, 231, 231, 0.2823529412);
            border: rgba(231, 231, 231, 0.0392156863) 1px solid;
            opacity: 0.6;
            padding: 6px 21px;
            margin-bottom: 10px;
            color: #030133;
            &::after {
              position: relative;
              right: -10px;
            }
          }
        }
      }

      .plan-name {
        font-size: 20px;
      }
      .macs {
        font-size: 20px;
      }
      .years {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .contact {
        margin-top: 15px;
      }
    }
    &.desktop {
      display: none;
      .right-side-wrap {
        margin-left: 130px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 85px;
        @include media-breakpoint-up(xl) {
          margin-left: 200px;
        }
        .contact {
          display: flex;
          align-items: flex-start;

          margin-top: 22px;
          @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
          }
          .contact-support {
            color: #74be0d;
            cursor: pointer;
            text-decoration: underline;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            @include media-breakpoint-up(xl) {
              bottom: -17px;
            }
          }
          .support-text {
            max-width: 300px;
            font-size: 18px;
            font-weight: 600;
          }
          .support-img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
        }
        .coming-soon {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          .seats {
            position: absolute;
            top: 55px;
            .seats-txt {
              margin-right: 5px;
              @include media-breakpoint-up(xl) {
                font-size: 18px;
              }
            }
          }
          .seats-dropdown {
            max-width: 75px;
            display: flex;
            justify-content: space-between;
            background-color: rgba(231, 231, 231, 0.2823529412);
            border: rgba(231, 231, 231, 0.0392156863) 1px solid;
            padding: 6px 21px;
            color: #030133;
            margin-right: 24px;
            opacity: 0.6;
            .dropdown-menu.show {
              z-index: 10;
              position: relative;
            }
            .dropdown-toggle {
              background-color: #e7e7e748;
              border: #e7e7e70a 1px solid;
              padding: 6px 21px;
              color: #030133;
              margin-right: 24px;
              &::after {
                position: relative;
                right: -10px;
              }
            }
          }
        }
      }
      @include media-breakpoint-up(lg) {
        display: block;
      }
      .wrapper {
        display: flex;
        .index {
          margin-right: 42px;
          font-size: 24px;
          font-weight: bold;
        }
        .product {
          display: flex;
          margin-top: 10px;
          @include media-breakpoint-up(xl) {
            min-width: 355px;
          }
          .product-img {
            margin-right: 25px;
          }
          .product-content {
            display: flex;
            flex-direction: column;
            .sub-details {
              margin-bottom: 18px;
              @include media-breakpoint-up(xl) {
                font-size: 18px;
              }
            }
            .plan-name {
              font-weight: bold;
              max-width: 300px;
            }
          }
        }
      }
    }
    position: relative;
    .contact {
      .support-txt {
        margin: 15px 0 23px 0;
        font-weight: 600;
      }
    }
    .plan-top {
      display: flex;
      margin-bottom: 22px;
      align-items: center;
      .index {
        margin-right: 20px;
        font-size: 24px;
        font-weight: bold;
      }
      .label {
        font-size: 18px;
      }
    }
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: inherit;
      content: '';
      opacity: 0;
      border: 1px solid #0091de;
      z-index: 0;
      pointer-events: none;
    }
    &.selected {
      &::after {
        animation: selected-plan 1s ease;
        animation-delay: 0.5s;
      }
    }
    .plan-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .product-img {
        display: flex;
        justify-content: center;
      }
      .label {
        font-size: 18px;
      }
      .bold {
        font-weight: bold;
      }
      .plan {
        font-size: 18px;
        margin-bottom: 20px;
      }
      .seats {
        margin-bottom: 19px;
      }
      .seats-dropdown {
        margin-top: 18px;
        max-width: 75px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(231, 231, 231, 0.2823529412);
        border: rgba(231, 231, 231, 0.0392156863) 1px solid;
        opacity: 0.6;
        padding: 6px 21px;
        color: #030133;
        margin-right: 24px;
        .dropdown-toggle {
          background-color: #fff;
          color: #030133;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            position: absolute;
            right: 10px;
          }
        }
        .select-item {
          text-align: center;
          position: relative;
          display: flex;
          justify-content: center;
          &.disabled {
            color: #e7e7e7;
          }
          &:hover {
            background-color: #74be26 !important;
            color: #fff;
          }
          &:active {
            color: #74be26;
          }
        }
      }
    }
  }
}
@keyframes selected-plan {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.not-found {
  padding: 150px 0;
  position: absolute;
  border-radius: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  text-align: center;
  height: 460px;

  .not-found-txt,
  .register-support {
    max-width: 350px;
    font-size: 17px;
    font-weight: bold;
    padding: 0 20px;
  }
}

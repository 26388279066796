// Popover
.popover {
  $pophover-dark: #030133;
  border-radius: 11px;
  background: $pophover-dark;
  border-color: $pophover-dark;
  box-shadow: 0 3px 6px rgba(0,0,0, .16);


  // Body
  .popover-body {
    color: #FFFFFF;
  }
  // Arrow
  .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;

    &::before, &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  // Top Arrow
  &.bs-popover-top > .arrow, &.bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px);

    &::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: $pophover-dark;
    }

    &::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: $pophover-dark;
    }
  }

  // Bottom Arrow
  &.bs-popover-bottom > .arrow, &.bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);

    &::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: $pophover-dark;
    }

    &::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: $pophover-dark;
    }
  }

  // Left Arrow
  &.bs-popover-left > .arrow, &.bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;

    &::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: $pophover-dark;
    }

    &::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: $pophover-dark;
    }
  }

  // Right Arrow
  &.bs-popover-right > .arrow, &.bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;

    &::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: $pophover-dark;
    }

    &::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: $pophover-dark;
    }
  }
}

.btn {
  padding: 0.375rem 1.75rem;
}

.btn-success {
  background-color: #74BE0D;
  border-color: #74BE0D;
  font-weight: bold;

  &:hover {
    background-color: #fff;
    color: #74BE0D;
  }
  &:focus{
    background-color: #74BE0D;
    color:white;
    border-color: #74BE0D;
  }

 
}

.btn-outline-success {
  border-color: #74BE0D;
  color: #74BE0D;
  font-weight: bold;

  &:hover {
    background-color: #74BE0D;
    border-color: #74BE0D;
  }

  &:focus {
    background-color: #3d6701;
    border-color: #3d6701;
    box-shadow: 0 0 0 0.25rem rgba(61, 103, 1, .5);
  }
}

.btn-outline-secondary{
  border-color: #02022C;
  color: #02022C;
  font-weight: bold;

  &:hover {
    background-color: #02022C;
    border-color: #02022C;
  }

  &:focus {
    background-color: #02022C;
    border-color: #02022C;
    box-shadow: 0 0 0 0.25rem rgb(2, 2, 44);
  }
}

.btn-max {
  min-width: 190px;
  padding: 0.375rem 2.5rem;
  font-size: 17px;
}

.btn-md {
  padding: 0.375rem 2.5rem;
}

.btn-blank {
  padding-left: 5px;
  padding-right: 5px;

  &:focus,
  &:focus-visible {
    outline: 0;
    box-shadow: none;
  }
}
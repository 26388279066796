.tooltip-input {
  position: relative;

  .form-control {
    display: inline-block;
  }
  svg {
    margin: 0;
    display: inline-block;
    transform: scale(0.7);

    .tspan_i {
      transition: all 0.36s ease-in-out;
    }

    &:hover {
      opacity: 0.8;
      cursor: help;
    }
  }
}

.input-wrap {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  &.last-input {
    margin-bottom: 40px;
  }
  &.red {
    input {
      box-shadow: 0 0 0 1px #c90808 !important;
    }
  }
  input {
    box-sizing: border-box;
    font-size: 12px;
    height: 37px;
    max-width: 426px;
    margin: auto;
    position: relative;
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
    ~ .eye-icon {
      pointer-events: none;
      svg {
        stroke: #9d9d9d;
      }
    }
    @include media-breakpoint-up(xl) {
      height: 51px;
      font-size: 16px;
    }
    &:focus {
      box-shadow: 0 0 0 1px #02022c;
      border-color: transparent;
    }

    &.focused {
      padding-top: 12px;
      padding-bottom: 0;
      ~ .eye-icon {
        pointer-events: auto;
        svg {
          stroke: #383859;
        }
      }
      + .placeholder {
        top: 4px;
        font-size: 9px;
        position: absolute;

        @include media-breakpoint-up(xl) {
          font-size: 11px;
        }
      }
    }
    + .placeholder {
      font-size: 12px;
      transition: 0.3s ease;
      color: #a7a7a7;
      position: absolute;
      text-transform: capitalize;
      top: 10px;
      left: 10px;
      pointer-events: none;
      @include media-breakpoint-up(xl) {
        left: 14px;
        top: 13px;
        font-size: 16px;
      }
    }
  }
  input:-internal-autofill-selected {
    appearance: menulist-button;
  }
}

.err-msg {
  color: #c90808;
  font-size: 12px;
  margin-top: 5px;
}

.input-group {
  .form-control {
    width: 100%;
  }
}

.page--account {
  input {
    margin: 0;
  }
  .input-wrap {
    width: 70%;
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}

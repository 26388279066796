.page--main {
  .breadcrumbs-wrap {
    display: none;
  }
  .card-wrapper {
    margin-bottom: 25px;

    a {
      text-decoration: none;
      color: #030133;
      font-size: 15px;
      line-height: 22px;
    }

    .card-nav {
      display: flex;
      background-color: white;
      width: 100%;
      height: 100%;
      padding: 15px 33px 6px;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transform-style: preserve-3d;
      transition: 0.3s box-shadow cubic-bezier(0.155, 1.105, 0.295, 1.12);
      cursor: pointer;
      font-size: 13px;
      @include media-breakpoint-up(md) {
        min-height: 206px;
        padding: 45px 35px 25px;
      }
      .icon {
        margin-right: 17px;
        margin-top: 5px;

        svg {
          transition: all 0.3s cubic-bezier(0.155, 1.105, 0.295, 1.12);
        }
      }

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
      }

      .content {
        .title {
          font-size: 14px;
          font-weight: bold;
          line-height: 24px;
          text-transform: uppercase;
          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

html:lang(de) .page--main .card-wrapper .card-nav .content .title {
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

html:lang(de) p {
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

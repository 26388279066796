.loading {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(3, 1, 51, 0.6);
  z-index: -1;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  
  .circle {
    margin-right: 10px;
    animation: pulse 1s ease infinite;
  }

  .circle-2{
    animation-delay: .9s;
}
.circle-3{
    animation-delay: .7s;
}
  &.shown {
    z-index: 10;
    opacity: 1;
  }
}
@keyframes pulse {
    0%{transform: scale(.5);}
    50%{transform: scale(1);}
    100%{transform: scale(.5);}
}

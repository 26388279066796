.modal-confirm {
  font-weight: 400;
  text-transform: uppercase;
  &:active,
  &:focus {
    background-color: #74be0d;
    border-color: #74be0d;
    color: #fff;
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #fff;
    color: #74be0d;
  }
}

.modal-wrap {
  .background {
    position: fixed;
    right: 0;
    top: 0;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    background-color: #030133;
    z-index: 1000;
  }
  .modal-alert {
    border-radius: 10px;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 500px;
    width: 90%;
    z-index: 1001;
    background-color: #fff;
    opacity: 1;
    overflow: hidden;
    height: fit-content;
    min-height: 270px;
    .bold {
      font-weight: bold;
    }
    .modal-top {
      background-color: #030133;
      height: 65px;
      width: 100%;
      color: #fff;
      font-size: 21px;
      font-weight: bold;
      padding: 3% 3%;
      padding: 24px 0 14px 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .x-icon {
      top: 7px;
      right: 23px;
      position: absolute;
    }
    .modal-body {
      padding: 5% 5%;
      font-size: 15px;
      color: #030133;
      .are-you-sure {
        margin-top: 15px;
      }
      .btns-row {
        display: flex;
        margin-top: 30px;
        justify-content: flex-end;
      }
    }
  }
}

.card-wrapper-row {
  margin: 0 auto;
  background-color: white;
  padding: 35px 4.33333% 60px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  position: relative;
  @include media-breakpoint-up(xl) {
    padding: 35px 7.33333% 60px;
  }
  .fail-msg {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: #c90808;
    margin-top: 30px;
  }
  .success-msg {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: #74be0d;
    margin-top: 30px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 9px;
    }
  }
  .save-btn {
    width: 100%;
    font-size: 13px;
    max-width: 159px;
    @include media-breakpoint-up(xl) {
      max-width: 195px;
      font-size: 17px;
    }
  }
  .right-loader {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }
  .arrow-wrap {
    display: none;
  }
  a {
    font-weight: 600;
  }
  &.subs {
    .title-wrapper {
      position: relative;
      padding: 0;
    }
    @include media-breakpoint-down(lg) {
      overflow: hidden;
      max-height: 220px;
      transition: 1s ease;
      &:nth-of-type(1) {
        max-height: 260px;
      }
      .arrow-wrap {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        content: '';
        z-index: 1;
        height: 57px;
        left: 0;
        cursor: pointer;
        @include media-breakpoint-up(md) {
          height: 65px;
        }
        .arrow {
          transition: 0.7s ease;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
    &.collapsed {
      max-height: 1000px;
    }
  }
  .divider {
    width: 100%;
    background-color: #9d9d9d;
    height: 1px;
    content: '';
    opacity: 0.6;
    margin: 30px 0;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .choose-new {
    max-width: 80%;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 8px;
  }
  #newEmailInfo {
    z-index: 100;
    position: absolute;
    top: -195px;
    right: -14px;
    @include media-breakpoint-up(md) {
      top: -155px;
    }
    @include media-breakpoint-up(lg) {
      top: -9px;
      right: -45px;
    }
    @include media-breakpoint-up(xl) {
      top: -2px;
      right: 27px;
    }
  }

  .form-control {
    border-radius: 3px !important;
  }
  .eye-icon {
    position: absolute;
    right: 13px;
    top: 4px;

    @include media-breakpoint-up(xl) {
      right: 102px;
      top: 12px;
    }
  }

  .update-email,
  .update-password {
    width: 100%;
    max-width: 250px;
  }
  // overwrite title wrapper
  .title-wrapper {
    margin: 0;

    .page-title-separator {
      margin-bottom: 20px;
    }
  }
  .invoice-history-wrap {
    display: flex;
    flex-direction: column;
    .card-row {
      &.invoice-history {
        @include media-breakpoint-up(lg) {
          margin-top: 40px;
        }
        .invoice-history-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include media-breakpoint-up(lg) {
            flex-direction: row;
          }
        }
      }
    }
    .billing-details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .date-wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }

        .no_renewal {
          color: #030133;
          font-size: 18px;
          font-weight: 800;
          font-family: Source Sans Pro;
        }
      }
      .date {
        font-weight: 600;
      }
    }
    .date {
      font-weight: 600;
    }
  }
  // Card label wrapper
  .card-label-wrapper {
    padding-right: 2%;
    @include media-breakpoint-up(lg) {
      padding-right: 6%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 0;
    }
    &.content {
      padding-left: 44px;
    }
    .card-label-link {
      align-items: flex-start;
      flex-direction: column;
      text-align: left;

      .download-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .profile-form {
      &.profile-form-password {
        .form-group {
          .input-wrap {
            input {
              width: 100%;
            }
            .eye-icon {
              @include media-breakpoint-up(xl) {
                right: 20px;
              }
            }
          }
          .input-group-text {
            right: 71px;
          }
        }
      }
      &.profile-form-email {
        .form-btns {
          @include media-breakpoint-up(xl) {
            width: 80%;
          }
        }
      }
      .form-btns {
        display: flex;
        justify-content: space-between;
        button {
          font-size: 12px;
          @include media-breakpoint-up(xl) {
            font-size: 15px;
          }
        }
      }
      margin-top: 5px;
      .form-group {
        input {
          @include media-breakpoint-up(xl) {
            width: 83%;
          }
          &::placeholder {
            font-weight: 300;
          }
        }
        .input-group-text {
          background-color: transparent;
          border: transparent;
          top: 12px;
          right: 145px;
          position: absolute;
          z-index: 100;
          cursor: pointer;
          height: 30px;
        }
      }
    }
    .profile-form-anim {
      animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    }

    .success-change {
      margin: 15px 0 -15px 5px;
      font-size: 14px;
      font-weight: 600;
      color: #74be0d;
      svg {
        margin-right: 3px;
      }
    }

    &.spreader {
      position: relative;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        right: 0;
        top: -5%;
        background-color: rgba(199, 199, 199, 0.5);
        height: 110%;
        width: 1px;

        @include media-breakpoint-up(lg) {
          content: '';
        }
      }
    }

    .value-strong {
      font-weight: bold;
    }

    .label,
    .value {
      font-size: 15px;
      line-height: 25px;
      @include media-breakpoint-up(xl) {
        font-size: 17px;
      }
    }

    // Regular card label
    .card-label {
      display: flex;
      margin-bottom: 20px;
      margin-top: 5px;
      align-items: center;
      flex-wrap: wrap;

      .label {
        margin-right: 14px;
        font-weight: bold;
      }
    }

    // Row card label
    .card-row {
      margin-top: 10px;
      margin-bottom: 35px;
      .active-section-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: flex-end;
        @include media-breakpoint-up(md) {
          gap: 24px;
        }

        .change-plan {
          display: inline-block;
          color: #69b300;
          cursor: pointer;
          text-decoration: underline;
          font-weight: 600;
          margin-top: 7px;
        }
		
		.cancel-subscription {
          display: inline-block;
          color: #69b300;
          cursor: pointer;
          text-decoration: underline;
          font-weight: 600;
          margin-top: 7px;
        }
        
        .download-installer {
          display: inline-block;
          color: #69b300;
          cursor: pointer;
          text-decoration: underline;
          font-weight: 600;
        }
      }
      
      .separator-md {
        	margin-top: 16px;
       }
       
       .separator-lg {
        	margin-top: 32px;
       }
       .margin-top-minus-md {
        	margin-top: -12px;
       }
    
      .card-label-row {
        max-width: 95%;
        .details-wrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          .order-number {
            position: absolute;
            left: -35px;
            font-weight: bold;
            font-size: 18px;
            text-align: left;
            color: #030133;
            @include media-breakpoint-up(lg) {
              left: -50px;
            }
            @include media-breakpoint-up(xl) {
              font-size: 24px;
            }
          }
        }
        .label {
          font-size: 16px;
          line-height: 22px;
        }

        .value {
          margin: 0 0 11px;
          font-weight: 600;

          &.value-strong {
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            gap: 9px;
            align-items: center;
          }
        }
            
        .separator {
        	margin-top: 6px;
        }
        
      }

      .IPP-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 62px;

        @include media-breakpoint-up(md) {
          margin-top: 39px;
        }

        .login-credentials {
          display: flex;
          gap: 12.45px;

          .login-text {
            color: #030133;
            font-size: 18px;
            font-weight: 500;
          }

          .ipp-eye-icon {
            cursor: pointer;
            width: 20px;
            height: 13px;

            img {
              width: 100%;
              height: 100%;
            }

            &.disabled {
              opacity: 0.2;
              cursor: default;
            }
          }
        }

        .credentials-content {
          margin-bottom: 23px;

          .username-text,
          .password-text {
            font-size: 17px;
            color: #030133;
            font-weight: 600;
            display: flex;
            gap: 5px;
            align-items: center;

            span {
              display: inline-block;
              &.field_type {
                min-width: 85px;
              }
              &:not(.field_type) {
                margin: 0 5px 0 5px;
                font-size: 13px;
                max-width: 290px;
                word-break: break-all;
                top: 2px;
                position: relative;
              }
            }
          }
          .copyIcon {
            cursor: pointer;
            svg {
              g {
                path {
                  transition: 0.3s ease;
                }
              }
            }
          }
        }

        .devices-links {
          display: flex;
          flex-direction: column;
          gap: 16px;

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }
        }
      }
    }

    // Link card label
    .card-label-link {
      .label {
        font-size: 16px;

        &.value-strong {
          font-size: 18px;
        }
      }

      .value {
        font-size: 18px;
      }
    }
  }
}

html:lang(de) .title-wrapper.sub .page-title .text.subtitle {
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}

html:lang(de) .title-wrapper .page-title-separator .text.subtitle {
  font-size: 12.5px;
}

.ipp-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding: 0 12px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #030133;
    opacity: 0.78;
  }

  .close-modal {
    position: absolute;
    font-size: 18px;
    top: 12px;
    right: 12px;
    width: 28px;
    height: 28px;
    z-index: 101;
    cursor: pointer;
  }

  .login-form {
    background-color: white;
    padding: 32px 24px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    position: relative;
    max-width: 369px;
    z-index: 102;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: #030133;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      width: 80%;
      margin-bottom: 20px;
    }

    .form {
      width: 100%;
      .inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .input-wrap {
          margin-bottom: 0;
          .input {
            width: 100%;
          }
        }

        .login-btn-wrap {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

.cancel-subscription-modal-wrapper {
  .background {
    position: fixed;
    right: 0;
    top: 0;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    background-color: #030133;
    z-index: 1000;
  }
  .container {
    border-radius: 10px;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 600px;
    width: 90%;
    z-index: 1001;
    background-color: #fff;
    opacity: 1;
    overflow: hidden;
    height: fit-content;
    min-height: 270px;
    .bold {
      font-weight: bold;
    }
    .top {
      background-color: #030133;
      height: 65px;
      width: 100%;
      color: #fff;
      font-size: 21px;
      font-weight: bold;
      padding: 3% 3%;
      padding: 24px 0 14px 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .x-icon {
      top: 7px;
      right: 23px;
      position: absolute;
    }
    .informative {
     	color: #8a8a8a;
    }
    .body {
      padding: 5% 5%;
      font-size: 15px;
      color: #030133;
      .are-you-sure {
        margin-top: 20px;
      }
      .btns-row {
        display: flex;
        margin-top: 30px;
        justify-content: flex-end;
      }
    }
  }
}


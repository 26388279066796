@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/sans/source-sans-pro-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/sans/source-sans-pro-v14-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:url('../../fonts/sans/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/sans/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src:url('../../fonts/sans/source-sans-pro-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/sans/source-sans-pro-v14-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src:url('../../fonts/sans/source-sans-pro-v14-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/sans/source-sans-pro-v14-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/sans/source-sans-pro-v14-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}

.maintenance {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 80%;
  margin: auto;
  .maintenance_image {
    max-width: 600px;
  }
  h1 {
    margin-top: 35px;
  }
}

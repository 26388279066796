a {
  color: #69B300;

  &:hover {
    color: #538c01;
    text-decoration: none;
  }
  &:focus {
    color: #3d6701;
  }
}
.language_switcher_wrap {
  right: 0;
  position: absolute;
  top: 15px;
  align-items: center;
  color: #404041;
  cursor: pointer;
  z-index: 10;
  @include media-breakpoint-up(xl) {
    right: 51px;
  }
  &.header_style {
    position: relative;
    top: 0;
    right: 0;
    margin-left: -25px;
    .selected_language {
      color: #fff;
    }
    .dropdown {
      .dropdown-menu {
        left: -30px !important;
        top: 17px !important;
      }
    }
  }
  .language_switcher {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 7px 12px;
    &:focus {
      outline: 1px solid #5e5ba3;
      box-shadow: none;
    }
    .globe_icon {
      display: flex;
      align-items: center;
    }
    .selected_language {
      margin: 0 2px 0 4px;
    }
    .language_dropdown {
      position: absolute;
      width: 128px;
      right: -25px;
      top: 65px;
      height: auto;
      overflow: hidden;
      max-height: 0;
      border-radius: 13px;
      background-color: #fff;
      transition: max-height 0.7s ease;

      .lang {
        display: flex;
        align-items: center;
        height: 36px;
        transition: background-color 0.5s ease;
        padding: 21px 17px;
        &:hover {
          background-color: rgba(117, 190, 38, 0.21);
        }

        &.selected {
          font-weight: 700;
        }
      }
    }
  }
  .dropdown {
    button {
      background: transparent;
      color: #404041;
      border: 0;
    }
  }
  .dropdown-menu {
    min-width: 128px;
    width: 128px;
    max-width: 128px;
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    left: -15px !important;
    box-shadow: 0px 3px 10px #00000029;
    @include media-breakpoint-up(lg) {
      box-shadow: none;
    }
    @include media-breakpoint-up(xl) {
      left: -25px !important;
    }
    &.show {
      top: 10px !important;
    }
    a {
      text-decoration: none;
    }
    .dropdown-item {
      padding: 10px 19px;
      height: 50px;
      color: #030133;
      font-size: 18px;
      text-decoration: none !important;
      border: none;
      &:hover {
        background-color: #e1f1d0;
      }
      &:active {
        background-color: #fff;
      }
      &.logout {
        border-top: 1px solid gray;
      }
    }
  }
}

header.app-header {
  padding: 0.625rem;
  color: white;
  background-color: #030133;

  // Wrapper
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }

    // Logo Wrapper
    .logo-wrapper {
      display: flex;
      align-items: center;
      margin: 0 auto 0 0;
      text-decoration: none;
    }

    // User Wrapper
    .user-wrapper {
      position: relative;
      display: flex;
      margin: 0 1.3rem 0 0.3rem;
      font-size: 15px;

      @include media-breakpoint-down(md) {
        display: none;
      }

      strong {
        margin: 0 0.3rem;
      }

      &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: #74be0d;
        border-radius: 100%;
        top: 8px;
        left: -15px;
      }
    }

    // Button wrapper
    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 0.3rem;

      .menu-icon {
        width: 51px;
        height: 51px;
        background-size: contain;
        background: url('../../media/icons/menu-icon.svg');
        content: '';
        transition: 0.2s ease;
        border: 0;
        border-radius: 3px;
        box-shadow: 0;
        &:hover {
          box-shadow: rgb(241, 241, 241) 0px 1px 5px;
        }
      }
      .btn-outline-link {
        color: #f8f8f9;
        border-color: #f8f8f9;
        padding: 0.375rem 2rem;

        &:hover {
          color: #030133;
          border-color: #030133;
          background-color: #f8f8f9;
        }
      }
    }

    .menu-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .toggle {
        background-color: transparent;
        border: 0;
        padding: 0 0 0 0;
        width: 63px;
        height: 51px;
      }
      .dropdown-menu {
        border-radius: 12px;
        padding: 0;
        min-width: 12rem;
        overflow: hidden;
        &.show {
          top: 20px !important;
          @include media-breakpoint-up(lg) {
            // transition: .3s ease;
            left: -46px !important;
          }
        }
        a {
          text-decoration: none;
        }
        .dropdown-item {
          padding: 10px 19px;
          height: 50px;
          color: #030133;
          font-size: 18px;
          text-decoration: none !important;
          border: none;
          &:hover {
            background-color: #e1f1d0;
          }
          &:active {
            background-color: #fff;
            color: #74be26;
          }
          &.logout {
            border-top: 1px solid gray;
          }
        }
      }
      .btn {
        color: #f8f8f9;

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.25rem transparent;
          color: #befdff;
        }
      }
    }
  }
}

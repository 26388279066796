.app-container {
  position: relative;
  padding: 0;
  margin: 0;
  //min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.background-circles {
  position: fixed;
  background-color: #F9F9FA;
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  .circle134,
  .circle216,
  .circle213,
  .circle213-2,
  .circle211,
  .circle366 {
    position: absolute;
    transform-origin: center;
    animation: rotate 150s linear infinite;
  }

  .left {
    position: relative;
    left: -2vw;
    top: 15vh;

    .circle366 {
      top: 5vh;
      left: -5vw;
      animation-duration: 250s;
    }

    .circle134 {
      top: 22vh;
      left: 5vw;
      animation-direction: reverse;
    }

    .circle216 {
      top: 0;
      left: 10vw;
      animation-direction: reverse;
    }

    .circle211 {
      top: 65vh;
      left: 5vw;
    }

    .circle213 {
      top: 52vh;
      left: -2vw;
      animation-direction: reverse;
    }

    .circle213-2 {
      top: 40vh;
      left: -4vw;
    }
  }

  .right {
    position: relative;
    right: -2vw;
    top: 15vh;
    transform: scaleX(-1) translateX(-85%);

    .circle366 {
      top: 5vh;
      right: -5vw;
      animation-duration: 250s;
    }

    .circle134 {
      top: 22vh;
      right: 5vw;
      animation-direction: reverse;
    }

    .circle216 {
      top: 0;
      right: 10vw;
      animation-direction: reverse;
    }

    .circle211 {
      top: 65vh;
      right: 5vw;
    }

    .circle213 {
      top: 52vh;
      right: -2vw;
      animation-direction: reverse;
    }

    .circle213-2 {
      top: 40vh;
      right: -4vw;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}


.page--devices {
  .label {
    font-size: 15px;
    font-weight: bold;
    color: #030133;
  }
  .sub-label {
    font-size: 13px;
    color: #a7a7a7;
  }
  .mobile-wrapper {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .card-wrapper-row {
      padding: 25px 8.33333% 19px;
      .block {
        margin-bottom: 25px;
      }
      .serial-number-text {
        font-size: 15px;
        color: #030133;
        padding-top: 10px;
      }
      .actions-wrap {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding-right: 15%;
        .seats,
        .installer,
        .deauthorize {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .installer-icon,
          .trash-icon {
            cursor: pointer;
          }
        }
      }
    }
  }
  .export {
    background-size: contain;
    width: 18px;
    height: 20px;
    position: relative;
   	float: right;
   	margin-top: -10px;
    .active{
      cursor: pointer;
    }
    a {
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
      padding-right: 4px;
    }
  }
  .desktop-wrapper {
    @include media-breakpoint-down(lg) {
      display: none;
    }

    .card-wrapper-row {
      padding: 21px 1.33333% 30px;
      position: relative;
      .skel-device {
        height: 30px;
        margin-bottom: 7px;
      }
      .table-top {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid #69b300;
        display: flex;
        padding-bottom: 10px;
        margin: auto;
        .table-header {
          text-transform: capitalize;
          text-align: left;
          &.device-name-header {
            width: 22%;
          }
          &.serial-number-header {
            width: 30%;
            text-transform: none;
          }
          &.seats-header {
            width: 16%;
          }
          &.installer-header {
            width: 18%;
          }
          &.deauthorize-heaer {
            width: 15%;
          }
        }
      }
    }
    .products-wrap {
      display: flex;
      flex-direction: column;
      .product {
        min-height: 135px;
        margin: auto;
        width: 91%;
        display: flex;
        padding-top: 37px;
        border-top: 1px solid #cfcfcf;
        font-size: 15px;

        &:nth-of-type(1) {
          border-top: none;
        }
        .plans {
          display: flex;
          flex-direction: column;
          width: 100%;
          .product-wrap {
            width: 100%;
            display: flex;
          }
        }
        .react-loading-skeleton {
          height: 59px;
          margin-bottom: 20px;
        }

        .keys {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
        .device-name {
          width: 28%;
        }
        .products {
          width: 40%;
          display: flex;
          flex-direction: column;
        }
        .seats {
          width: 19%;
        }
        .installer {
          width: 24%;
          .toastBtn {
            color: #74be0d;
            cursor: pointer;
            text-decoration: underline;
            font-weight: 600;
          }
        }
        .deauthorize {
          .modalBtn {
            cursor: pointer;
            text-decoration: underline;
            font-weight: 600;
            color: #c90808;
          }
        }
        svg {
          cursor: pointer;
        }
      }
      .device-identifier {
      	width: 91%;
      	margin: auto;
        display: flex;
        padding-bottom: 4px;
        border-bottom: 1px solid #cfcfcf;
        font-size: 13px;
        
        .device-id {
		  color: #707B7C;
          font-weight: 600;
        }
      
      }
    }
  }
}

.actions-wrap {
  text-align: center;
}

.device-name-title .sub-label {
  text-align: left;
}

html:lang(de) .page--devices .mobile-wrapper .card-wrapper-row .actions-wrap .deauthorize,
html:lang(fr) .page--devices .mobile-wrapper .card-wrapper-row .actions-wrap .deauthorize {
  margin-left: 10px;
}

html:lang(de) .page--devices .mobile-wrapper .card-wrapper-row .actions-wrap .installer,
html:lang(fr) .page--devices .mobile-wrapper .card-wrapper-row .actions-wrap .installer {
  margin-left: 10px;
}

.seats-number {
  padding-top: 7px;
}

html:lang(de) .actions-wrap,
html:lang(ja) .actions-wrap,
html:lang(fr) .actions-wrap {
  width: 300px;
}

html:lang(de) .deauthorize {
  width: 350px;
}

html:lang(en) .modal-alert,
html:lang(de) .modal-alert,
html:lang(ja) .modal-alert {
  max-width: 550px;
}
html:lang(fr) .modal-alert {
  max-width: 580px;
}
html:lang(es) .modal-alert {
  max-width: 600px;
}

html:lang(de) .page--devices .desktop-wrapper .card-wrapper-row .table-top {
  font-size: 15px;
}

html:lang(de) .page--devices .desktop-wrapper .card-wrapper-row .table-top {
  @include media-breakpoint-up(md) {
    font-size: 13px;
  }
}

.seats-number {
  @include media-breakpoint-up(md) {
    padding-top: 10px;
  }
}

html:lang(de) .actions-wrap {
  @include media-breakpoint-up(md) {
    width: 600px;
  }
}

.page--invoices {
  position: relative;
  .card-wrapper-row {
    position: relative;
    padding: 0;
    &.loading-table{
      padding: 0 0 20px 0 ;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
    .table-top {
      width: 100%;
      padding: 40px 4.33333% 9px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 3px solid #69b300;
      display: flex;
      justify-content: space-between;
      .table-header {
        width: 16%;
      }
    }
  }
  .download {
    background-size: contain;
    width: 18px;
    height: 20px;
    position: relative;
   
    .active{
      cursor: pointer;
    }
    a {
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .invoices-cards-wrap {
    display: none;
    position: relative;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    .skel-wrap {
      width: 100%;
      .invoices-skel-mobile {
        height: 105px;
        width: 87%;
        max-width: 450px;
        min-width: 250px;
        border-radius: 10px;
        margin: 0 auto 20px auto;
      }
      span {
        display: flex;
        flex-direction: column;
        margin: auto;
      }
    }
    .invoices-card {
      width: 87%;
      min-width: 250px;
      background-color: #fff;
      border-radius: 10px;
      padding: 1em 2em;
      box-sizing: border-box;
      position: relative;
      margin: 0 auto 20px auto;
      height: 105px;
      position: relative;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      max-width: 450px;

      .download {
        position: absolute;
        top: 16px;
        right: 34px;
      }
      .title {
        color: #a7a7a7;
        text-transform: capitalize;
      }
      .reference {
        color: #030133;
        font-weight: bold;
        font-size: 15px;
      }
      .date-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .date {
          color: #030133;
          font-size: 15px;
        }
      }
    }
  }
  .table-item {
    min-height: 87px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 28px 4.33333% 28px;
    &:nth-last-of-type(1){
      border-bottom: none;
    }
    .item {
      width: 16%;
    }
    .pdf {
      .downalod {
        position: relative;
        top: unset;
        right: unset;
      }
    }
  }

  .invoices-skel {
    height: 39px;
    display: flex;
    width: 92%;
    padding-bottom: 20px;
    margin: 25px auto 25px auto;
  }
}

@import '~bootstrap/scss/bootstrap';
@import 'fonts/sans-pro';

body {
  font-family: 'Source Sans Pro', system-ui -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #030133;
}

@include media-breakpoint-up(xxl) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
    padding: 0;
  }
}

.swal2-popup.swal2-toast.swal2-show {
  animation: none !important;
}
.btn-success {
  &[disabled] {
    background-color: #e7e7e7;
    border: #e7e7e7 1px solid;
  }
  text-transform: capitalize;
}
.link {
  color: #74be0d;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

// Layouts
@import 'layouts/Buttons';
@import 'layouts/Animations';
@import 'layouts/Inputs';
@import 'layouts/Pophovers';
@import 'layouts/Href';
@import 'layouts/Wrapper';
@import 'layouts/Header';
@import 'layouts/TitleHolder';
@import 'layouts/LanguageSwitcher';
@import 'layouts/LoginHandler';
// pages
@import 'pages/MainWelcome';
@import 'pages/Account';
@import 'pages/invoices';
@import 'pages/devices.scss';
@import 'pages/plan.scss';
@import 'pages/not-found';
@import 'pages/login.scss';
// utilits
@import 'pages/modals';
@import 'pages/toast';
@import 'pages/breadcrumbs.scss';
@import 'pages/errorHandler.scss';
@import 'pages/loading.scss';

.breadcrumbs-wrap {
  position: relative;
  top: 30px;
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  .breadcrumb {
    margin-bottom: 18px;
  }
  .breadcrumb-item-wrap {
    display: flex;
    align-items: center;
    a {
      text-decoration: none !important;
    }
    .item {
      color: #030133;
      text-transform: capitalize;
      font-size: 15px;
      cursor: pointer;
      // margin-right: 5px;
      &:hover {
        span {
          text-decoration: underline;
        }
      }
      &.last {
        color: #69b324;
        font-weight: bold;
        pointer-events: none;
      }
    }
    .breadcrumbIcon {
      margin: 0 10px;
      top: -1px;
      position: relative;
      svg {
        width: 10px;
      }
    }
  }
}

.error{
    position: absolute;
    top: 0;
    overflow: hidden;
    left: 0;
    background-color: #fff;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 2rem;
    border-radius: 10px;
}
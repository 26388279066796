.card-wrapper-row {
  margin: 0 auto;
  background-color: white;
  padding: 35px 4.33333% 60px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  position: relative;
  &.new_flow {
    max-width: 506px;
  }
  @include media-breakpoint-up(xl) {
    padding: 35px 7.33333% 60px;
  }
  a {
    font-weight: 400;
  }

  .email {
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 13px;
    @include media-breakpoint-up(xl) {
      font-size: 25px;
      margin-bottom: 18px;
    }
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #030133;
    margin-top: 18px;
    max-width: 90%;
    margin-bottom: 36px;
    margin: auto;
    @include media-breakpoint-up(xl) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
  .inputs {
    border-radius: 10px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    width: 100%;
    &.error-input,
    .error-input {
      .form-control,
      input {
        box-shadow: rgba(201, 8, 8, 1) 0px 1px 2px, rgba(201, 8, 8, 1) 0px 0px 0px 2px;
      }
      .err {
        font-size: 11px;
        color: #c90808;
        position: relative;
        &.main {
          left: 0;
          right: 0;
        }
      }
    }
    .input {
      &.err {
        box-shadow: 0 0 0 1px #c90808 !important;
      }
      width: 100%;
    }
    .password-wrap {
      position: relative;
      .eye-icon {
        position: absolute;
        right: 12px;
        cursor: pointer;
        top: 4px;
        height: 14px;
        width: 23px;
        cursor: pointer;

        @include media-breakpoint-up(xl) {
          height: 17px;
          top: 12px;
          right: 18px;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      gap: 24px;
    }
  }
  .continue-btn {
    background: #74be0d;
    border-radius: 4px;
    width: 100%;
    font-size: 18px;
    color: #fff;
    border: none;
    padding: 6px 0px;
  }
  &.disabled {
    background-color: #e7e7e7;
    border: #e7e7e7 1px solid;
  }
  .err-msg {
    color: #cc0d18;
    font-size: 13px;
  }
  .support-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    .support-img {
      margin-bottom: 25px;
    }
    .support-desc {
      margin-bottom: 27px;
    }
    .support-link {
      color: #74be0d;
      font-size: 13px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
